<template>
  <v-dialog v-model="modalOpen" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">Selecione um Paciente</span>
      </v-card-title>

      <v-card-text>
        <v-select
          v-model="selectedPaciente"
          :items="pacientesUnicos"
          label="Escolha um paciente"
          item-text="paciente"
          item-value="paciente_id"
          outlined
          dense
        ></v-select>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="fecharModal">Cancelar</v-btn>
        <v-btn color="primary" :disabled="!selectedPaciente" @click="generatePDF">
          Gerar Relatório
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UtilsPDF from "../../service/utilsPDF";
import UtilsFunc from "../../service/utilsFunc";
import logo from "../../assets/logo.png";
import gptw from "../../assets/GPTW_2023-2024.png";
import ona from "../../assets/ONA_Nivel_3.png";

const { withCRUDUtils } = UtilsFunc;

export default {
  name: "ProdutividadeDetalhada",
  data: () =>
    withCRUDUtils({
      loading: false,
      modalOpen: true, // Estado do modal
      selectedPaciente: null, // Paciente selecionado
    }),
  props: {
    dados: { type: Array, default: () => [] },
    data_inicio: String,
    data_fim: String,
    showModal: {  // ✅ Adicionando a prop para evitar erro
      type: Boolean,
      required: true
    }
  },
  computed: {
    // Lista única de pacientes para o select
    pacientesUnicos() {
      const pacientesMap = new Map();
      const pacientesFiltrados = this.dados.filter(item => !item.resumo);
      pacientesFiltrados.forEach((item) => {
        if (!pacientesMap.has(item.paciente_id)) {
          pacientesMap.set(item.paciente_id, {
            paciente: item.paciente,
            paciente_id: item.paciente_id,
          });
        }
      });
      return Array.from(pacientesMap.values());
    },
  },
  methods: {
    
    fecharModal() {
      // this.modalOpen = false;
      this.$emit("update:showModal", false);
      
      // // ✅ Adicionando um pequeno atraso antes de resetar a seleção
      // setTimeout(() => {
      //   this.selectedPaciente = null; // Limpa o paciente selecionado
      // }, 300);
      // // this.$emit("fechar-modal");
    },

    // Filtra os dados pelo paciente antes de gerar o relatório
    drawHeader(doc) {
      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
      } catch (error) {
        this.$toast.error("Falha ao adicionar a imagem ao PDF: (L-34):", error);
      }
      doc.setFont("helvetica", "normal");
      doc.setDrawColor(57, 106, 159);
      doc.setLineWidth(1);
      doc.line(5, 5, 205, 5);

      doc.setDrawColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18); // Tamanho da fonte, ajuste conforme necessário
      doc.text("São Luís Home Care", 105, 15, { align: "center" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text(`Relatório de Produção Detalhada (por Paciente)`, 97, 23, { align: "center" });

      moment.locale("pt-br"); // garantir que seja horario de brasília independente do dispositivo

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");

      doc.setFontSize(8);
      doc.text("Data e Hora da Impressão:", 200, 32, { align: "right" });
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, 200, 36, {
        align: "right",
      });


      doc.setFontSize(11);
      doc.text(`Período de: ${UtilsPDF.formatDate(this.data_inicio)} até: ${UtilsPDF.formatDate(this.data_fim)}`, 71, 35);

    },
    async generatePDF() {
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );

      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);

      const lineHeight = 4;
      let yPosition = 35;

      doc.setFont("helvetica", "normal");
      yPosition += lineHeight * 2;

      const blueColor = [57, 106, 159];


      // Filtra os dados para remover itens que têm 'resumo'
      const detalhamentoEvolucoes = this.dados.filter(item => !item.resumo).filter(item => item.paciente_id === this.selectedPaciente);
      // console.log(detalhamentoEvolucoes)
      // Verifica se há evoluções para o paciente selecionado
      if (detalhamentoEvolucoes.length === 0) {
        this.$toast.error("Nenhuma evolução encontrada para o paciente selecionado.");
        return;
      }

      // Ordena os dados filtrados por Data da Evolução (crescente)
        const sortedDetalhamento = detalhamentoEvolucoes.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateA - dateB; // Ordena em ordem crescente
        });



      // Adiciona o título da segunda tabela
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text(`Detalhamento das Evoluções`, 105, yPosition, { align: "center" });

      // Adiciona um espaço antes de iniciar a segunda tabela
      yPosition += lineHeight * 2;


      let counter = 1;

      const pageWidth = doc.internal.pageSize.width; // Largura da página em mm
      const marginLeft = 10; // Margem esquerda em pixels
      const marginRight = 15; // Margem direita em pixels
      const usableWidth = pageWidth - (marginLeft + marginRight) / (doc.internal.scaleFactor || 1);

      // Segunda tabela: Detalhes dos Itens
      doc.autoTable({
        startY: yPosition,
        theme: 'striped',
        margin: { left: marginLeft, right: marginRight },
        tableWidth: usableWidth,
        headStyles: {
          fillColor: blueColor,
          halign: 'center',
          valign: 'middle'
        },
        styles: {
          lineColor: blueColor,
          lineWidth: 0.1,
          halign: 'left', // Alinha o texto à esquerda para evitar cortes
          overflow: 'linebreak', // Habilita a quebra de linha automática
          cellWidth: 'wrap' // Permite que as células se expandam conforme necessário
        },
        head: [['', 'Profissional', 'Id Paciente', 'Paciente', 'Id Evol.', 'Data da Evolução', 'Evolução Válida', 'Valor']],
        body: sortedDetalhamento.map(item => [
          counter++,
          item.colaborador_nome,
          item.paciente_id,
          item.paciente,
          item.evo_id,
          UtilsPDF.formatDate(item.date),
          item.assinatura ? 'Sim' : 'Não',
          item.assinatura ? UtilsPDF.formatToBRL(item.valor) : '--'
        ]),
        columnStyles: {
          0: { cellWidth: 10 },
          1: { cellWidth: 35 },
          2: { cellWidth: 20 },
          3: { cellWidth: 40 },
          4: { cellWidth: 15 },
          5: { cellWidth: 25 },
          6: { cellWidth: 20 },
          7: { cellWidth: 20 },
        },
      });

      UtilsPDF.addPageNumbers(doc);

      const filename = `Relatório Produtividade Detalhada - Período ${UtilsPDF.formatDate(this.data_inicio)} - ${UtilsPDF.formatDate(this.data_fim)}.pdf`;

      // UtilsPDF.savePDF(doc, filename, this.$toast);
      doc.save(filename);
      this.$toast.success('O download do relatório foi realizado com sucesso!');
    },
  },
  
  
};
</script>

<style scoped>
.text-green {
  color: green;
}
.text-red {
  color: red;
}
</style>
